import Typography from '../typography/Typography'
import './StyledLoading.scss'

const StyledLoading = () => {
  return (
    <div className='styled-loading'>
      <div className='title'>
        <Typography variant='h1'>Hello!</Typography>
      </div>
      <div className='subtitle'>
        <Typography variant='h3'>Portfolio is loading...</Typography>
      </div>
    </div>
  )
}

export default StyledLoading