import './App.scss'
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom"
import React, { useContext } from 'react'
import { ThemeContext } from './contexts/themeContext'
import WebPortfolio from './pages/web-portfolio/WebPortfolio'

const AppContainer = () => {
  const { darkMode } = useContext(ThemeContext)

  return (
    <div className={`app-container theme-${darkMode ? 'dark' : 'light'}`}>
      <BrowserRouter>
        <Routes>
          <Route exact path='/' element={<WebPortfolio theme={darkMode} />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default AppContainer
