import './Typography.scss'

const Typography = ({ children, variant, className }) => {
  return (
    <div className={`typography typography-${variant} ${className ? className : ''}`}>
      {children}
    </div>
  )
}

export default Typography