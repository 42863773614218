import React, { createContext, useEffect, useState } from 'react';
import { menuTabs } from '../utils/variables'

const ScrollContext = createContext();

function ScrollSectionProvider(props) {
  const [section, setSection] = useState(0)
  const [isClicked, setIsClicked] = useState(false)

  useEffect(() => {
    if (isClicked) {
      setTimeout(() => {
        setIsClicked(false)
      }, 800)
    }
  }, [isClicked])

  useEffect(() => {
    window.location.replace(`#${menuTabs[section]}`)
  }, [section])

  return (
    <ScrollContext.Provider value={{ section, setSection, isClicked, setIsClicked }}>
      {props.children}
    </ScrollContext.Provider>
  )
}

export { ScrollContext, ScrollSectionProvider }