import './App.scss'
import React, { Suspense, lazy } from 'react'
import { DarkModeProvider } from './contexts/themeContext'
import StyledLoading from './components/loading/StyledLoading'
import { ScrollSectionProvider } from './contexts/scrollContext'
import AppContainer from './AppContainer'

const App = () => {

  return (
    <Suspense fallback={<StyledLoading />}>
      <DarkModeProvider>
        <ScrollSectionProvider>
          <AppContainer />
        </ScrollSectionProvider>
      </DarkModeProvider>
    </Suspense>
  )
}

export default App
