import React, { createContext, useState } from 'react';

const ThemeContext = createContext();

function DarkModeProvider(props) {
  const [darkMode, setDarkMode] = useState(true)

  const toggleDarkMode = (dark) => {
    setDarkMode(!darkMode)
  }

  return (
    <ThemeContext.Provider value={{ darkMode, toggleDarkMode }}>
      {props.children}
    </ThemeContext.Provider>
  )
}

export { ThemeContext, DarkModeProvider }