import { createTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { screenSizeVals } from '../utils/screenSizes'

const useDeviceDetection = () => {

  const breakTheme = createTheme({
    breakpoints: {
      values: screenSizeVals
    }
  })

  const mobileScreen = useMediaQuery(breakTheme.breakpoints.down("mobile"))
  const tabletScreen = useMediaQuery(breakTheme.breakpoints.down("tablet"))
  const laptopScreen = useMediaQuery(breakTheme.breakpoints.down("laptop"))
  const largeScreen = useMediaQuery(breakTheme.breakpoints.down("large"))

  const deviceType = mobileScreen ? 'mobile' : '' ||
    tabletScreen ? 'tablet' : '' ||
      laptopScreen ? 'laptop' : '' ||
        largeScreen ? 'large' : ''
  return deviceType
}

export default useDeviceDetection
