export const menuTabs = ['home', 'about', 'career', 'contact']

export const mainTechStack = [
  {
    name: 'React',
    picture: '/logos/react.png',
    exp: '3 years',
  },
  {
    name: 'HTML',
    picture: '/logos/html.png',
    exp: '3 years',
  },
  {
    name: 'CSS & SCSS',
    picture: '/logos/css.png',
    exp: '3 years',
  },
  {
    name: 'JavaScript',
    picture: '/logos/js.png',
    exp: '3 years',
  },
  {
    name: 'Django',
    picture: '/logos/django.png',
    exp: '1 year',
  },
  {
    name: 'Unity',
    picture: '/logos/unity.png',
    exp: '3.5 years',
  },
  // {
  //   name: 'GIT',
  //   picture: '/logos/git.png',
  //   exp: '5 years'
  // },
]

export const otherTools = [
  'Unit testing',
  'Documentation',
  'C#',
  'Sprint planning',
  'Code review',
  'Management',
  'Git',
]

export const experience = [
  {
    date: '2023',
    name: 'Senior front-end developer (freelancer)',
    company: 'Maoni',
    description:
      'As a freelancer, I designed the UX/UI and developed the entire front-end for a customizable QR code-based form feedback tool. The functionalities include user authentication, business management, form and QR code creation, and a stats dashboard for the MVP stage.',
    attributions: ['Design UX/UI', 'Create reusable and maintainable components', 'Integrate APIs'],
    skills: ['React', 'JavaScript', 'CSS & SCSS', 'HTML'],
  },
  {
    date: '2020 - 2023',
    name: 'Front-end / Senior front-end developer',
    company: 'Predict Systems',
    description:
      'As a front-end developer, I created reusable components, wrote maintainable code, and optimized performance. I developed backend REST APIs for minor functionalities, seamlessly integrating them into the front-end. As my role evolved, I led sprint plannings, task creation, facilitated communication and validated features and progress with UX and Data Science teams. Additionally, I managed front-end documentation, provided instructions to the front-end team, and coordinated deployment with the other teams.',
    attributions: [
      'Create reusable and maintainable components',
      'Write unit and integration tests',
      'Run sprint plannings and create development tasks',
      'Communicate progress between teams and ensure feature compatibility',
      'Write front-end documentation',
      'Create REST APIs',
    ],
    skills: ['React', 'JavaScript', 'CSS & SCSS', 'HTML', 'Django', 'Python'],
  },
  {
    date: '2020',
    name: 'Back-end developer',
    company: 'Predict Systems',
    description:
      'In my role as a back-end developer, I initiated a research project for an e-learning application, implemented user authentication and bulk user creation through token links, and transitioned to front-end development, where I created functional components and integrated APIs to enhance overall functionality.',
    attributions: [
      'Create APIs for user authentication on back-end',
      'Create user authentication functionalities on front-end',
      'Create and integrate components for forms',
    ],
    skills: ['Django', 'Python', 'React', 'JavaScript', 'CSS & SCSS', 'HTML'],
  },
  {
    date: '2017 - 2020',
    name: 'CTO & Co-founder',
    company: 'VR Rehab',
    description:
      'As a CTO of a small startup, my role was to manage and organize the development team, track and integrate VR trends (haptic gloves and standalone headsets), translate business and roadmap goals into tasks, as well as programming functionalities and creating scenes, writing APIs and integrating them in Unity.',
    attributions: [
      'Manage the development team',
      'Creating product roadmap',
      'Programming functionalities and creating scenes in Unity',
      'Integrate tools like haptic gloves and standalone headsets',
      'Add and integrate API endpoints to track stats and progress',
    ],
    skills: ['C#', 'Unity', 'Express', 'VR', 'Management'],
  },
  {
    date: '2017',
    name: 'Graduated, M.Sc. in Games',
    company: 'Aalborg University',
    description:
      'My masters thesis studied how to design and implement a virtual reality (VR) rehabilitation tool based on grounded theory and experiments conducted. Two studies determined: patients’ motivation to train with a VR rehabilitation tool, based on patient-therapist interaction and in-game adaptation technique, and the standardization of the rehabilitation game based on four parameters. The research was conducted on ten patients (experiment and control group), part of NeuroRehab Grindsted, Denmark. The system consisted of: Leap Motion – tracking controller, Oculus Rift Development Kit 2 - a HMD headset, a desktop computer (PC), and a custom built task simulator game.',
  },
]
