import './WebPortfolio.scss'
import { ScrollContext } from '../../contexts/scrollContext'
import { useContext, lazy } from 'react'
import useDeviceDetection from '../../custom-hooks/useDeviceDetection'
import ParticleCanvas from './ParticleCanvas'
const Home = lazy(() => import('./home/Home'))
const About = lazy(() => import('./about/About'))
const Experience = lazy(() => import('./exp/Experience'))
const Contact = lazy(() => import('./contact/Contact'))
const Footer = lazy(() => import('../../components/footer/Footer'))
const Menu = lazy(() => import('../../components/menu/Menu'))
const Sidebar = lazy(() => import('../../components/sidebar/Sidebar'))

const WebPortfolio = ({ theme }) => {
  const { section, setSection, isClicked, setIsClicked } = useContext(ScrollContext)
  const deviceType = useDeviceDetection()

  return (
    <div className={`web-portfolio-container ${deviceType}`}>
      <div className='content-cutoff'></div>
      <ParticleCanvas theme={theme} deviceType={deviceType} />
      <Menu section={section} setSection={setSection} setIsClicked={setIsClicked} device={deviceType} />
      {(deviceType !== 'mobile' && deviceType !== 'tablet') &&
        <Sidebar device={deviceType} />}
      <Home key='home-key' setSection={setSection} isClicked={isClicked} setIsClicked={setIsClicked} device={deviceType} />
      <About key='about-key' setSection={setSection} isClicked={isClicked} device={deviceType} />
      <Experience key='exp-key' setSection={setSection} isClicked={isClicked} device={deviceType} />
      <Contact key='contact-key' setSection={setSection} isClicked={isClicked} device={deviceType} />
      <Footer key='footer-key' setSection={setSection} isClicked={isClicked} device={deviceType} />
    </div>
  )
}

export default WebPortfolio