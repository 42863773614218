import { Canvas } from '@react-three/fiber'
import { Sparkles, Cloud } from '@react-three/drei'
import { screenSizeVals } from '../../utils/screenSizes'

const ParticleCanvas = ({ theme, deviceType }) => {

  const index = Object.keys(screenSizeVals)?.indexOf?.(deviceType)
  const coord = index === -1 ? 5 : index

  return (
    <Canvas style={{ position: 'fixed', width: '100%', height: '100%' }}>
      <ambientLight intensity={Math.PI / 1.5} />
      {theme ?
        <>
          <Sparkles
            count={25}
            size={Array.from({ length: 25 }, () => 0.5 + Math.random() * 8)}
            position={[coord, 1, 0]}
            scale={[4, 9, 0.1]}
            speed={0.5}
            opacity={0.2 + coord * 0.15}
            color={'#E28465'}
          />
          <Sparkles
            count={25}
            size={Array.from({ length: 25 }, () => 0.5 + Math.random() * 8)}
            position={[-coord, 1, 0]}
            scale={[4, 9, 0.1]}
            speed={1}
            opacity={0.2 + coord * 0.15}
            color={'#E28465'}
          />
        </>
        :
        <>
          <Cloud
            seed={15}
            position={[coord, 1, 0]}
            volume={1} color="orange" speed={1} fade={100} growth={3}
            opacity={0.4 + coord * 0.1}
            rotation={[Math.PI / 2, Math.PI / 2, 0]}
          />
          <Cloud
            seed={10}
            position={[-coord, 1, 0]}
            volume={1} color="hotpink" speed={1} fade={100} growth={3}
            opacity={0.4 + coord * 0.1}
            rotation={[Math.PI / 2, Math.PI / 2, 0]}
          />
        </>
      }

    </Canvas>
  )
}

export default ParticleCanvas